
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, toastController, IonSpinner, IonCardHeader, IonCardContent, IonCard, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;
import { camera } from 'ionicons/icons';

export default defineComponent({
  name: 'InseratCreateStep5',
  data () {
    return {
      finishedLoading: true,
      isUploading: false,
      errorWhileUploading: false,
      finishedUploading: false,
      errorWhileLoading: false,
      cameraIcon: camera
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonSpinner,
    IonCardHeader, IonCardContent, IonIcon, IonCard
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' })
    }

    // check if data is collected from previous steps
    if (!localStorage.inseratType){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratTitle){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratDescription){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCategoryId){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratPrice){
      this.$router.push({ path: '/inserat/create' })
    }
    if (!localStorage.inseratCurrency){
      this.$router.push({ path: '/inserat/create' })
    }
    if (localStorage.inseratIsGoodCause && !localStorage.inseratDonationRecipientId){
      this.$router.push({ path: '/inserat/create' })
    }

    if (localStorage.persistedInserat){
      // if it's not possible to find the ID of the latest persisted Inserat go to User Inserats
      this.$router.push({ path: '/profile/inserats' })
    }

    // check step
    if (localStorage.inseratStep !== 5){
      // redirect to right step
      if (localStorage.inseratStep == 0){
        this.$router.push({ path: '/inserat/create' });
      } else {
        this.$router.push({ path: '/inserat/create/step-' + localStorage.inseratStep });
      }
    }

    this.openToast('Inserat wurde erstellt', 3000, "success");

  },
  methods: {
    addLater(){
        this.$router.push({ path: '/profile/inserats'});
    },
    uploadImage() {

      if (this.imageUrl) {

        this.isUploading = true;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const currentView = this;

        // convert dataUrl from Image to File
        const timestamp = Math.floor(Date.now() / 1000);
        const fileSuffix = ".jpeg";
        const imageFile = this.dataURLtoFile(this.imageUrl, '' + timestamp + fileSuffix);

        const inseratId = '/inserats/' + localStorage.persistedInserat;
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("inserat", inseratId);
        axios.post(process.env.VUE_APP_API_URL + "/inserat_images", formData, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        })
            .then(function () {
              localStorage.persistedImage = true;
            }).catch(function (error) {
          localStorage.persistedImage = false;
          this.errorWhileUploading = true;
          console.log(error);
        }).finally(function () {
          currentView.isUploading = false;
          currentView.finishedUploading = true;
        });
      }

    },
    dataURLtoFile(dataurl, filename) {

      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
    },
    goToFirstStep(){
      // reset inserat local storage
      localStorage.inseratStep = 0;
      localStorage.inseratType = null;
      localStorage.inseratTitle = null;
      localStorage.inseratDescription = null;
      localStorage.inseratCategoryId = null;
      localStorage.inseratPrice = null;
      localStorage.inseratCurrency = null;
      localStorage.inseratIsGoodCause = null;
      localStorage.inseratDonationRecipient = null;
      localStorage.inseratDonationRecipientId = null;
      this.$router.push({ path: '/inserat/create' })
    },
    async openToast(message: string, duration: number, toastColor = "danger") {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: toastColor
          })
      return toast.present();
    },
    nextStep() {
      // after that go to next step
      localStorage.inseratStep = 6;
      this.$router.push({ path: '/inserat/create/step-6' })
    }
  },
  setup() {
    const imageUrl = ref<string | null>();
    const showUploadButton = ref<boolean | false>();
    const takePicture = async () => {
      // Otherwise, make the call:
      try {
        const image = await Camera.getPhoto({
          quality: 100,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Prompt,
        });
        // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
        imageUrl.value = image.dataUrl;

        showUploadButton.value = true;

      } catch (e) {
        console.log("error", e);
      }
    };
    return {
      takePicture,
      imageUrl,
      showUploadButton
    };
  },
});

